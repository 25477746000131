import UrlRedirect from "../Components/UrlRedirect";

const Redirect = () => {
    return (
        <>
        <UrlRedirect />
        </>
    );
    }

export default Redirect;